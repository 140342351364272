import { FC } from "react";
import { withRoles, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { UserView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_CONTRACTOR_USER = gql`
  query GetMeterContractorUser($id: ID!) {
    user: getMeterContractorUser(id: $id) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress 
      gender
      status
      profileImageUrl
      meta {
        isPasswordSet
        isBlocked
        isDeleted
        lastLoginAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;

interface ViewContractorUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ViewContractorUserContainer: FC<ViewContractorUserContainerProps> = ({
  open,
  setOpen,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='User Information'
      description='Details of user are shown below'
      renderActions={() => (
        <>
          {withRoles(["Super"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      {data?.user?._id && <UserView user={data?.user} />}
    </Modal>
  );
};

export default ViewContractorUserContainer;
