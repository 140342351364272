import { gql, useQuery } from "@apollo/client";
import { MeterView, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const GET_METER = gql`
  query GetMeter($id: ID!) {
    meter: getMeter(id: $id) {
      _id
      code
      batchCode
      brand {
        _id
        code
        name
      }
      model {
        _id
        code
        name
        phase
        type
        digits
        voltage
        currentRating
        mechanism
        communicationType
      }
      status
      location
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }  
      createdAt
      updatedAt
    }
  }
`;

export default function ViewMeterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_METER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title='Meter Information'
      description='Details of meter are shown below'
    >
      {data?.meter && <MeterView meter={data?.meter} />}
    </Modal>
  );
}
