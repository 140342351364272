import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, RescheduleScrapyardVisitationForm } from "components";
import { wrapClick } from "utils";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { GET_SCRAPYARD_VISITATION } from "./view";
import moment from "moment";

const RESCHEDULE_SCRAPYARD_VISITATION = gql`
  mutation RescheduleScrapyardVisitation($date: Date!, $id: ID!) {
    rescheduleScrapyardVisitation(date: $date, id: $id) {
      _id
    }
  }
`;

export default function RescheduleScrapyardVisitationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();

  const { data, loading: getLoading } = useQuery(GET_SCRAPYARD_VISITATION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const [rescheduleScrapyardVisitation, { loading }] = useMutation(
    RESCHEDULE_SCRAPYARD_VISITATION
  );
  const form = useFormik({
    initialValues: {
      oldDate: "",
      newDate: "",
    },
    onSubmit: async (values) => {
      await rescheduleScrapyardVisitation({
        variables: {
          id: searchParams.id,
          date: values.newDate,
        },
      }).then(({ data }) => {
        if (data.rescheduleScrapyardVisitation._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Scrapyard Appointment Rescheduled Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not reschedule Scrapyard Appointment",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setFieldValue(
      "oldDate",
      moment(data?.scrapyardVisitation?.date).format("YYYY-MM-DD")
    );
  }, [data?.scrapyardVisitation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Reschedule Scrapyard Appointment"}
      loading={getLoading}
      description={"Provide the details to reschedule a scrapyard appointment"}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Rescheduling..." : "Reschedule"}
          </button>
        </>
      )}
    >
      <RescheduleScrapyardVisitationForm form={form} />
    </Modal>
  );
}
