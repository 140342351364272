import {
  ArrowDownCircleIcon,
  ArrowsPointingOutIcon,
  ArrowTopRightOnSquareIcon,
  CalculatorIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  FingerPrintIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  MagnifyingGlassPlusIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  TrashIcon,
  UserIcon,
  UserPlusIcon,
  XCircleIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { classNames, wrapClick } from "utils";
import lodash from "lodash";

const Actions = [
  "update",
  "view",
  "configure",
  "disapprove",
  "delete",
  "calculate",
  "assign",
  "schedule",
  "send",
  "resolve",
  "approve",
  "reset",
  "reject",
  "reassign",
  "expand",
  "goto",
  "clone",
  "enable",
  "disable",
  "investigate",
  "view-work-order",
  "download",
  "reset-order",
] as const;
export type Action = (typeof Actions)[number];
const ActionIcons: { [key in Action]: typeof EyeIcon } = {
  approve: HandThumbUpIcon,
  disapprove: HandThumbDownIcon,
  assign: UserPlusIcon,
  calculate: CalculatorIcon,
  configure: Cog8ToothIcon,
  reassign: UserIcon,
  reject: XCircleIcon,
  resolve: ClipboardDocumentListIcon,
  schedule: CalendarDaysIcon,
  send: PaperAirplaneIcon,
  update: PencilSquareIcon,
  view: EyeIcon,
  expand: ArrowsPointingOutIcon,
  goto: ArrowTopRightOnSquareIcon,
  clone: DocumentDuplicateIcon,
  investigate: MagnifyingGlassPlusIcon,
  delete: TrashIcon,
  disable: NoSymbolIcon,
  enable: CheckCircleIcon,
  reset: FingerPrintIcon,
  download: ArrowDownCircleIcon,
  "view-work-order": EyeIcon,
  "reset-order": ArrowPathRoundedSquareIcon,
};

interface ActionButtonProps {
  action: Action;
  onClick: (...val: any) => any;
  disabled?: boolean;
  tooltip?: string;
}

const ActionButton: FC<ActionButtonProps> = ({
  action,
  onClick,
  disabled = false,
  tooltip,
}) => {
  const Icon = ActionIcons[action];

  return (
    <button
      data-tooltip-delay-show={1000}
      data-tooltip-id='global-tooltip'
      data-tooltip-content={tooltip ?? lodash.startCase(action)}
      type='button' 
      onClick={wrapClick(onClick)}
      disabled={disabled}
      className={classNames(
        disabled
          ? "cursor-not-allowed text-gray-500 hover:bg-gray-300"
          : "text-gray-500 hover:bg-gray-300 hover:text-gray-900",
        "inline-flex items-center rounded-full border border-transparent p-1  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      )}
    >
      <Icon className='h-5 w-5' aria-hidden='true' />
    </button>
  );
};
export default ActionButton;
